<template>
  <div>
    <b-card title="Soru İstatistikleri">
      <b-tabs>
        <b-tab title="TUS Denemeleri">
          <div style="min-height: 350px">
            <b-row>
              <b-col md="4" class="mt-1">
                <b-form-group>
                  <v-select
                    v-model="selectedUserCategoryOption"
                    placeholder=""
                    :options="userCategoryOptions"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4" class="mt-1">
                <b-form-group>
                  <v-select
                    v-model="selectedSubjectForTrialExamFilter"
                    placeholder="TÜM ALANLAR"
                    :options="subjectOptions"
                    :filterBy="filterBy"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4" class="mt-1">
                <b-form-input
                  placeholder="Soru içeriği ara"
                  v-model="trialExamQuestionFilter"
                />
              </b-col>
            </b-row>

            <b-table
              responsive
              hover
              selectable
              select-mode="single"
              @row-selected="onTrialExamQuestionSelected"
              :per-page="trialExamQuestionsPerPage"
              :current-page="trialExamQuestionsCurrentPage"
              :items="filteredTrialExamQuestions"
              :fields="trialExamQuestionsTableFields"
            >
              <template #cell(index)="data">
                {{
                  (trialExamQuestionsCurrentPage - 1) *
                    trialExamQuestionsPerPage +
                  data.index +
                  1
                }}
              </template>
              <template #cell(exam_name)="data">
                <p style="margin-bottom: 0px">
                  {{
                    data.item.exam_name
                      .replaceAll("TUSBuddy", "")
                      .replaceAll("TUS Denemeleri", "TD")
                  }}
                </p>
              </template>
              <template #cell(title)="data">
                {{ toTitleCase(data.value) }}
              </template>
              <template #cell(subtitle)="data">
                {{ toTitleCase(data.value) }}
              </template>
              <template #cell(question_text)="data">
                <div style="max-width: 540px">
                  <p
                    style="
                      margin-bottom: 0px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                    "
                  >
                    {{ data.value }}
                  </p>
                </div>
              </template>
              <template #cell(correct_percentage)="data">
                <p style="text-align: center">% {{ data.value }}</p>
              </template>
              <template #cell(favorite_count)="data">
                <p style="text-align: center">
                  {{ data.item.favorite_count }} 
                </p>
              </template>
              <template #cell(answer_count)="data">
                <p
                  style="
                    text-align: center;
                    margin-bottom: 0;
                    font-size: 0.95rem;
                    font-style: italic;
                  "
                >
                  {{ data.item.answer_count }}
                </p>
              </template>
              <template #cell(favorite_percentage)="data">
                <p
                  style="
                    text-align: center;
                    margin-bottom: 0;
                    font-size: 0.95rem;
                    font-style: italic;
                  " 
                >
                  % {{ data.item.favorite_percentage }}
                </p>
              </template>
              
            </b-table>
            <b-pagination
              v-if="
                filteredTrialExamQuestions.length > trialExamQuestionsPerPage
              "
              v-model="trialExamQuestionsCurrentPage"
              :total-rows="filteredTrialExamQuestions.length"
              :per-page="trialExamQuestionsPerPage"
              :align="'right'"
              class="mb-2"
            />
          </div>
        </b-tab>

        <b-tab title="Ders Denemeleri">
          <div style="min-height: 350px">
            <b-row>
              <b-col md="4" class="mt-1">
                <b-form-group>
                  <v-select
                    v-model="selectedUserCategoryOption"
                    placeholder=""
                    :options="userCategoryOptions"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4" class="mt-1">
                <b-form-group>
                  <v-select
                    v-model="selectedSubjectForLessonExamFilter"
                    placeholder="TÜM ALANLAR"
                    :options="subjectOptions"
                    :filterBy="filterBy"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4" class="mt-1">
                <b-form-input
                  placeholder="Soru içeriği ara"
                  v-model="lessonExamQuestionFilter"
                />
              </b-col>
            </b-row>

            <b-table
              responsive
              hover
              selectable
              select-mode="single"
              @row-selected="onLessonExamQuestionSelected"
              :per-page="lessonExamQuestionsPerPage"
              :current-page="lessonExamQuestionsCurrentPage"
              :items="filteredLessonExamQuestions"
              :fields="lessonExamQuestionsTableFields"
            >
              <template #cell(index)="data">
                {{
                  (lessonExamQuestionsCurrentPage - 1) *
                    lessonExamQuestionsPerPage +
                  data.index +
                  1
                }}
              </template>
              <template #cell(exam_name)="data">
                <p style="margin-bottom: 0px">
                  {{ toTitleCase(data.item.title) }} -
                  {{ data.item.exam_order }}
                </p>
              </template>

              <template #cell(subtitle)="data">
                {{ toTitleCase(data.value) }}
              </template>
              <template #cell(question_text)="data">
                <div style="max-width: 540px">
                  <p
                    style="
                      margin-bottom: 0px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                    "
                  >
                    {{ data.value }}
                  </p>
                </div>
              </template>
              <template #cell(correct_percentage)="data">
                <p style="text-align: center">% {{ data.value }}</p>
              </template>
              <template #cell(favorite_count)="data">
                <p style="text-align: center">
                  {{ data.item.favorite_count }}
                </p> 
              </template>
              <template #cell(answer_count)="data">
                <p
                  style="
                    text-align: center;
                    margin-bottom: 0;
                    font-size: 0.95rem;
                    font-style: italic;
                  "
                >
                  {{ data.item.answer_count }}
                </p>
              </template>
              <template #cell(favorite_percentage)="data">
                <p
                  style="
                    text-align: center;
                    margin-bottom: 0;
                    font-size: 0.95rem;
                    font-style: italic;
                  " 
                >
                  % {{ data.item.favorite_percentage }}
                </p>
              </template>
            </b-table>
            <b-pagination
              v-if="
                filteredLessonExamQuestions.length > lessonExamQuestionsPerPage
              "
              v-model="lessonExamQuestionsCurrentPage"
              :total-rows="filteredLessonExamQuestions.length"
              :per-page="lessonExamQuestionsPerPage"
              :align="'right'"
              class="mb-2"
            />
          </div>
        </b-tab>
      </b-tabs>
    </b-card>

    <b-card v-if="selectedQuestion" style="margin: 1% 0">
      <div style="padding: 1%">
        <div v-if="selectedQuestion">
          <div
            v-html="getSelectedQuestionText()"
            style="margin-bottom: 20px; font-size: 1.15rem"
          ></div>

          <div
            v-for="(option, index) in options"
            :key="index"
            style="margin-top: 10px; display: table"
          >
            <b-button
              :variant="getOptionButtonClass(selectedQuestion, option)"
              class="rounded-circle"
              style="
                padding: 6px;
                height: 26px;
                width: 26px;
                margin-right: 12px;
                display: table-cell;
              "
              size="sm"
            >
              {{ option }}
            </b-button>
            <div style="display: table-cell; width: 100%">
              <p
                :class="getOptionTextClass(selectedQuestion, option)"
                style="font-size: 1.1rem; margin-bottom: 0px"
              >
                {{ getSelectedQuestionOptionText(option) }}
              </p>
              <div style="display: flex">
                <b-progress
                  v-if="selectedQuestion.distributionLoaded"
                  style="flex-grow: 1; margin-top: 10px; height: 3px !important"
                  v-model="
                    getOptionSelectionDistributionPercentage(option).value
                  "
                  class="progress-bar-primary"
                  max="100"
                >
                </b-progress>
                <div v-else style="flex-grow: 1; height: 14px"></div>
                <div style="width: 50px; text-align: center">
                  <p style="font-size: 0.95rem; margin: 0 auto">
                    %{{
                      selectedQuestion.distributionLoaded
                        ? getOptionSelectionDistributionPercentage(option).value
                        : ""
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <b-alert variant="secondary" show style="margin-top: 15px">
            <div class="alert-body" style="padding: 3px 2px 3px 15px">
              <div style="display: flex">
                <p style="flex-grow: 1">Boş bırakanlar</p>
                <div style="width: 50px; text-align: center">
                  <p style="font-size: 0.95rem; margin: 0 auto">
                    %{{ getSelectionQuestionEmptyPercentage() }}
                  </p>
                </div>
              </div>
            </div>
          </b-alert>
        </div>

        <div class="mt-2" style="display: flex">
          <div style="flex-grow: 1">
            <p style="margin-bottom: 0; font-weight: 500">
              {{ selectedQuestion.title }}
            </p>
            <p style="font-weight: 500">
              {{ toTitleCase(selectedQuestion.subtitle) }}
            </p>
          </div>

          <div style="flex-grow: 1">
            <div style="display: block; float: right">
              <p
                class="text-primary"
                style="margin-bottom: 5px; margin-left: 2px; font-weight: 600"
              >
                Cevap: {{ selectedQuestion.answer }}
              </p>
              <b-badge
                v-if="!selectedQuestion.user_answer"
                variant="light-secondary"
              >
                Boş
              </b-badge>
              <b-badge
                v-else-if="
                  selectedQuestion.user_answer == selectedQuestion.answer
                "
                variant="light-success"
              >
                Doğru
              </b-badge>
              <b-badge v-else variant="light-danger" style="padding: 5px 15px">
                Yanlış
              </b-badge>
            </div>
          </div>
        </div>

        <hr style="height: 10px" />

        <b-card-text class="mt-3" style="white-space: pre-wrap">
          <div
            v-html="
              '<strong>Çözüm / Açıklama</strong> <hr/>' +
              getSelectedQuestionSolutionText()
            "
          />
        </b-card-text>

        <div class="mt-3" style="display: flex">
          <div class="flex-grow-1"></div>
          <b-button
            variant="warning"
            @click="copySelectedQuestion()"
            size="sm"
            style="float: right"
          >
            Soruyu Kopyala
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import store from "@/store/index";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import $ from "jquery";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BCard,
  BTable,
  BPagination,
  BRow,
  BCol,
  BProgress,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BBadge,
  BForm,
  BButton,
  BAlert,
  BTabs,
  BTab,
  BFormTextarea,
} from "bootstrap-vue";

export default {
  components: {
    vSelect,
    BCard,
    BTable,
    BPagination,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BProgress,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BBadge,
    BForm,
    BButton,
    BAlert,
    BTabs,
    BTab,
    BFormTextarea,
    flatPickr,
  },
  data() {
    return {
      trialExamQuestionFilter: "",
      lessonExamQuestionFilter: "",
      selectedSubjectForTrialExamFilter: "",
      selectedSubjectForLessonExamFilter: "",
      //
      selectedQuestion: null,
      options: ["A", "B", "C", "D", "E"],
      //
      trialExamQuestions: [],
      trialExamQuestionsPerPage: 10,
      trialExamQuestionsCurrentPage: 1,
      trialExamQuestionsTableFields: [
        { key: "index", label: "" },
        { key: "exam_name", label: "Sınav" },
        { key: "title", label: "Ders" },
        { key: "subtitle", label: "Konu" },
        { key: "question_text", label: "Soru" },
        { key: "correct_percentage", label: "Doğru Oranı", sortable: true },
        { key: "favorite_count", label: "Favori", sortable: true },
        { key: "favorite_percentage", label: "Fav. (%)", sortable: true },
        { key: "answer_count", label: "Cevaplayan", sortable: true },
      ],
      //
      lessonExamQuestions: [],
      lessonExamQuestionsPerPage: 10,
      lessonExamQuestionsCurrentPage: 1,
      lessonExamQuestionsTableFields: [
        { key: "index", label: "" },
        { key: "exam_name", label: "Sınav" },
        { key: "subtitle", label: "Konu" },
        { key: "question_text", label: "Soru" },
        { key: "correct_percentage", label: "Doğru Oranı", sortable: true },
        { key: "favorite_count", label: "Favori", sortable: true },
        { key: "favorite_percentage", label: "Fav. (%)", sortable: true },
        { key: "answer_count", label: "Cevaplayan", sortable: true },
      ],
      subjectOptions: [
        "ANATOMİ",
        "BİYOKİMYA",
        "DAHİLİYE",
        "FARMAKOLOJİ",
        "FİZYOLOJİ",
        "GENEL CERRAHİ",
        "KADIN DOĞUM",
        "KÜÇÜK STAJLAR",
        "MİKROBİYOLOJİ",
        "PATOLOJİ",
        "PEDİATRİ",
      ],
      userCategoryOptions: [
        "Tüm Kullanıcılar",
        "TUS Analiz Üyeleri",
      ],
      selectedUserCategoryOption: "Tüm Kullanıcılar",
      dataLoadCounter: 0,
    };
  },

  watch: {
    selectedUserCategoryOption: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.loadQuestionStatistics();
      }
    },
  },

  computed: {
    filteredTrialExamQuestions() {
      var filteredQuestions = [];
      var contentFilter = !this.trialExamQuestionFilter
        ? ""
        : this.trialExamQuestionFilter.toLocaleLowerCase("tr-TR");
      var selectedSubject = !this.selectedSubjectForTrialExamFilter
        ? ""
        : this.selectedSubjectForTrialExamFilter;
      this.trialExamQuestions.forEach((question) => {
        var questionFullText =
          question.question_text +
          question.option_a +
          question.option_b +
          question.option_c +
          question.option_d +
          question.option_e +
          question.solution;
        if (
          contentFilter.length == 0 ||
          questionFullText.toLocaleLowerCase("tr-TR").includes(contentFilter)
        ) {
          if (
            selectedSubject.length == 0 ||
            question.title == selectedSubject
          ) {
            filteredQuestions.push(question);
          }
        }
      });
      return filteredQuestions;
    },
    filteredLessonExamQuestions() {
      var filteredQuestions = [];
      var contentFilter = !this.lessonExamQuestionFilter
        ? ""
        : this.lessonExamQuestionFilter.toLocaleLowerCase("tr-TR");
      var selectedSubject = !this.selectedSubjectForLessonExamFilter
        ? ""
        : this.selectedSubjectForLessonExamFilter;
      this.lessonExamQuestions.forEach((question) => {
        var questionFullText =
          question.question_text +
          question.option_a +
          question.option_b +
          question.option_c +
          question.option_d +
          question.option_e +
          question.solution;
        if (
          contentFilter.length == 0 ||
          questionFullText.toLocaleLowerCase("tr-TR").includes(contentFilter)
        ) {
          if (
            selectedSubject.length == 0 ||
            question.title == selectedSubject
          ) {
            filteredQuestions.push(question);
          }
        }
      });
      return filteredQuestions;
    },
  },
  methods: {
    getUserCategory() {
      var category = "";
      switch (this.selectedUserCategoryOption) {
        case "Tüm Kullanıcılar":
          category = "all-users";
          break;
        case "TUS Analiz Üyeleri":
          category = "tus-analiz-users";
          break;
        default:
          this.selectedUserCategoryOption = "Tüm Kullanıcılar";
          category = "all-users";
          break;
      }
      return category;
    },
    async onTrialExamQuestionSelected(items) {
      this.selectedQuestion = null;
      if (items && items.length > 0) {
        var question = items[0];
        if (!question.distributionLoaded) {
          var payload = {
            question: question,
            user_category: this.getUserCategory(),
          };
          await store.dispatch(
            "adminQuestionStatistics/loadTrialExamQuestionUserAnswerDistribution",
            payload
          );
        }
        this.selectedQuestion = question;
      }
    },

    async onLessonExamQuestionSelected(items) {
      this.selectedQuestion = null;
      if (items && items.length > 0) {
        var question = items[0];
        if (!question.distributionLoaded) {
          var payload = {
            question: question,
            user_category: this.getUserCategory(),
          };
          await store.dispatch(
            "adminQuestionStatistics/loadLessonExamQuestionUserAnswerDistribution",
            payload
          );
        }
        this.selectedQuestion = question;
      }
    },

    getSelectedQuestionOptionText(option) {
      var question = this.selectedQuestion;
      if (question && option) {
        switch (option) {
          case "A":
            return question.option_a;
          case "B":
            return question.option_b;
          case "C":
            return question.option_c;
          case "D":
            return question.option_d;
          case "E":
            return question.option_e;
        }
      }
      return "-";
    },

    getSelectedQuestionText() {
      if (!this.selectedQuestion) {
        return "Error!";
      }
      var text = this.selectedQuestion.question_text;
      if (text.toLocaleLowerCase("tr-TR").includes("script")) {
        return "Error!";
      }
      return (
        "<span style='font-weight: 600; color: #46b4ab;'>Soru (id - " +
        (this.selectedQuestion.teq_id
          ? this.selectedQuestion.teq_id
          : this.selectedQuestion.leq_id) +
        ") : </span>" +
        text
          .replaceAll("[[", "<img src='")
          .replaceAll("\n", "<br/>")
          .replaceAll(
            "]]",
            "' style='margin: 0px auto; margin-top: 10px; margin-bottom: 10px; max-width: 96%; display: block; max-height: 640px;'/>"
          )
      );
    },

    getSelectedQuestionSolutionText() {
      if (!this.selectedQuestion) {
        return "Error!";
      }
      var solution = this.selectedQuestion.solution;
      if (solution.toLocaleLowerCase("tr-TR").includes("script")) {
        return "Error!";
      }
      return solution
        .replaceAll("[[", "<img src='")
        .replaceAll(
          "]]",
          "' style='margin: 0px auto; max-width: 96%; display: block; max-height: 340px;'/>"
        );
    },

    getOptionSelectionDistributionPercentage(option) {
      var question = this.selectedQuestion;
      if (question && question.distributionLoaded) {
        var total = question.participant_count;
        if (total > 0) {
          switch (option) {
            case "A":
              return { value: Math.round((100 * question.a_count) / total) };
            case "B":
              return { value: Math.round((100 * question.b_count) / total) };
            case "C":
              return { value: Math.round((100 * question.c_count) / total) };
            case "D":
              return { value: Math.round((100 * question.d_count) / total) };
            case "E":
              return { value: Math.round((100 * question.e_count) / total) };
          }
        }
      }
      return { value: 0 };
    },

    getSelectionQuestionEmptyPercentage() {
      var question = this.selectedQuestion;
      if (question && question.distributionLoaded) {
        var total = question.participant_count;
        var empty =
          total -
          (question.a_count +
            question.b_count +
            question.c_count +
            question.d_count +
            question.e_count);
        return Math.round((100 * empty) / total);
      }
      return "";
    },

    getOptionButtonClass(selectedQuestion, option) {
      if (selectedQuestion.answer == option) {
        return "outline-primary";
      }
      return "outline-secondary";
    },

    getOptionTextClass(selectedQuestion, option) {
      if (selectedQuestion.answer == option) {
        return "font-weight-bolder text-primary";
      }
      return "";
    },

    toTitleCase(str) {
      return !str
        ? ""
        : str
            .replace("(", "( ")
            .toLocaleLowerCase("tr-TR")
            .replace(
              /(?:^|\s|,|;|!|:|-|\.|\?)[a-z0-9ğçşüöı]/g,
              function (match) {
                return match.toLocaleUpperCase("tr-TR");
              }
            )
            .replace("( ", "(");
    },

    filterBy(option, label, search) {
      return (
        (label || "")
          .toLocaleLowerCase("tr-TR")
          .indexOf(search.toLocaleLowerCase("tr-TR")) > -1
      );
    },

    async loadQuestionStatistics() {
      var category = this.getUserCategory();

      this.trialExamQuestions = [];
      this.lessonExamQuestions = [];

      this.$forceUpdate();

      this.dataLoadCounter++;
      var currentCounter = this.dataLoadCounter;
      var self = this;
      store
        .dispatch(
          "adminQuestionStatistics/getTrialExamQuestionStatistics",
          category
        )
        .then((response) => {
          if (response && currentCounter == self.dataLoadCounter) {
            self.trialExamQuestions = response;
            self.trialExamQuestions.forEach((question) => {
              var total = question.correct_count + question.wrong_count;
              question["answer_count"] = total;
              var correctPercentage = -1;
              correctPercentage = question.correct_count / total;
              correctPercentage = Math.round(correctPercentage * 100);
              question["correct_percentage"] = correctPercentage;

              question["favorite_percentage"] = Math.round(1000 * question.favorite_count / question.answer_count) / 10;
            });
            self.$forceUpdate();
          }
        });

      store
        .dispatch(
          "adminQuestionStatistics/getLessonExamQuestionStatistics",
          category
        )
        .then((response) => {
          if (response && currentCounter == self.dataLoadCounter) {
            self.lessonExamQuestions = response;
            self.lessonExamQuestions.forEach((question) => {
              var total = question.correct_count + question.wrong_count;
              question["answer_count"] = total;
              var correctPercentage = -1;
              correctPercentage = question.correct_count / total;
              correctPercentage = Math.round(correctPercentage * 100);
              question["correct_percentage"] = correctPercentage;

              question["favorite_percentage"] = Math.round(1000 * question.favorite_count / question.answer_count) / 10;
            });
            self.$forceUpdate();
          }
        });
    },

    showError(title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: message,
          variant: "danger",
        },
      });
    },

    showSuccess(title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: message,
          variant: "success",
        },
      });
    },

    async copySelectedQuestion() {
      if (this.selectedQuestion) {
        var questionJson = JSON.stringify(this.selectedQuestion);
        try {
          await navigator.clipboard.writeText(questionJson);
          this.showSuccess("Soru Kopyalandı");
        } catch ($e) {
          this.showError("Soru Kopyalanamadı!", "");
        }
      }
    },
  },
  async created() {
    let moduleId = "question_statistics";
    let moduleName = "Soru İstatistikleri"; 
    if(JSON.parse(localStorage.getItem("adminObject"))[moduleId] != 1){
      this.$router.push("/erisim-kapali?moduleName="+moduleName);
      return; 
    } 

    this.loadQuestionStatistics();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

[dir] .table th,
[dir] .table td {
  padding: 0.5rem 0.8rem;
}
</style>
